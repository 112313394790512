import React, { useState } from 'react'
import {
  Drawer,
  Box,
  Typography,
  ListItem,
  ListItemButton,
  Collapse,
} from '@mui/material'
import { HiOutlineMail } from 'react-icons/hi'
import { BsTelephone } from 'react-icons/bs'
import { FaRegClock } from 'react-icons/fa'
import { useNavigate, useLocation } from 'react-router-dom'

const AppDrawer = ({ show, setShow }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const drawerLinks = [
    {
      title: 'Home',
      route: '/',
    },
    {
      title: 'Know your Rights',
      route: '/know-your-rights',
      dropdown: true,
    },
    {
      title: 'Educate Yourself',
      route: '/educate-yourself',
    },
    {
      title: 'Blogs',
      route: '/blogs',
    },
    {
      title: 'Our Team',
      route: '/our-team',
    },
    {
      title: 'Star Associate',
      route: '/star-associate',
    },
    {
      title: 'Gallery',
      route: '/gallery',
    },
    {
      title: 'Work with us',
      route: '/work-with-us',
    },
  ]

  const rightsList = [
    {
      id: 1,
      title: 'As a consumer',
      route: 'consumer',
    },
    {
      id: 2,
      title: 'As an investor in India',
      route: 'invester-in-india',
    },
    {
      id: 3,
      title: 'As an employee in India',
      route: 'employee-in-india',
    },
    {
      id: 4,
      title: 'As a women',
      route: 'women',
    },
    {
      id: 5,
      title: 'As a senior citizen',
      route: 'senior-citizen',
    },
    {
      id: 6,
      title: 'As a man',
      route: 'man',
    },
    {
      id: 7,
      title: 'As a businessman',
      route: 'businessman',
    },
  ]

  const [isDropdownOpen, setisDropDownOpen] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Drawer
      open={show}
      onClose={handleClose}
      anchor="right"
      sx={{ '.MuiPaper-root': { width: '60%' } }}
    >
      <Box
        sx={{
          backgroundColor: '#D0A144',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle2" fontSize="30px" fontWeight={700}>
          Navbar
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#1F2732', height: '100%' }}>
        <Box
          sx={{ mt: '2rem', display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {drawerLinks.map((item) => (
            <>
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  onClick={() => {
                    if (item.dropdown) {
                      setisDropDownOpen((prev) => !prev)
                    } else {
                      navigate(item.route)
                    }
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontSize="18px"
                    color={
                      item.route === location.pathname ? '#D0A144' : '#fff'
                    }
                  >
                    {item.title}
                  </Typography>
                </ListItemButton>
              </ListItem>
              {item.dropdown && (
                <Collapse in={isDropdownOpen} unmountOnExit>
                  {rightsList.map((r) => (
                    <ListItem sx={{ marginLeft: 2 }} disablePadding key={r.id}>
                      <ListItemButton
                        onClick={() => {
                          navigate(`/know-your-rights/${r.route}`)
                          handleClose()
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize="15px"
                          color={
                            `/know-your-rights/${r.route}` === location.pathname
                              ? '#D0A144'
                              : '#fff'
                          }
                        >
                          {r.title}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              )}
            </>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: '#D0A144',
          padding: '1rem',
        }}
      >
        <Box display="flex">
          <HiOutlineMail size="1.5rem" color="white" />
          <Typography sx={{ ml: '4px', color: 'white' }}>
            pariharlaw@gmail.com
          </Typography>
        </Box>

        <Box display="flex">
          <BsTelephone size="1.05rem" color="white" />
          <Typography sx={{ ml: '4px', color: 'white' }}>
            +91 9574486593
          </Typography>
        </Box>

        <Box display="flex">
          <FaRegClock size="1.5rem" color="white" />
          <Typography sx={{ ml: '15px', color: 'white' }}>
            8:00 AM until 6:00 PM
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default AppDrawer
